import Vue from 'vue'
import VueRouter from 'vue-router'
import Courses from '../components/Courses'
import LoginCard from '../components/LoginCard'
import CourseWisePurchase from '../components/CourseWisePurchaseTa'



Vue.use(VueRouter)

const routes = [
  
{
  path : '/',
  name : "Courses",
  component : Courses
},
{
  path : '/login',
  name : "LoginCard",
  component : LoginCard
},
{
  path : '/CourseWisePurchase/:id/:title',
  name : "CourseWisePurchase",
  component : CourseWisePurchase
},

]

const router = new VueRouter({
  routes,
})

export default router
