<template>
       <v-app-bar class="green" dark
       app
       v-if="LOGIN"
       >
        <v-toolbar-title class="ml-0 pl-2" @click="gotoHome">
           <a><img src="../assets/logo.png" style="height:70px"/></a> 
          </v-toolbar-title>
          <div class="text-center ml-10"><b> COURSE WISE PURCHASED USERS </b></div>
          <v-spacer></v-spacer>
<v-btn class="white green--text" @click="logout"><b>LOG-OUT</b> </v-btn>
</v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    computed:{
      ...mapGetters(['LOGIN'])
    },
    methods:{
      logout(){
        this.$router.push('/login')
        this.$store.dispatch('SET_LOGIN',false)
      },
      gotoHome(){
        this.$router.push("/")
      }
    }
  }
</script>
