<template>
    <v-layout row wrap class="">
        <v-flex xs12>
            <v-layout xs12 row wrap >
                <v-flex xs12 md12>
                    <div class="text-center mt-8">
                        <v-progress-circular
                    :size="50"
                    :width="7"
                    color="green"
                    indeterminate
                    v-if="progress"
                    ></v-progress-circular>
                    </div>
                    
                </v-flex>
            <v-flex xs12 md12 pl-3>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                       ASHA SHARATH CULTURAL CENTER - CLASSES
                    </h3>
              </div>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <th class="text-left green--text">
                                PURCHASED USERS
                            </th>
                            <th class="text-left green--text">
                                DETAILS
                            </th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                                v-for="(item,index) in ascc.classes"
                                :key="item.id"
                            >
                            <td>{{ index+1 }}</td>
                            <td>{{ item.name }}</td>  
                            <td>{{item.count}}</td>
                            <td> <v-btn @click="details(item.id,item.name)" text ><v-icon>search</v-icon></v-btn>  </td>

                            </tr>
                        </tbody>
                     
                        </template>
                </v-simple-table>
            </v-flex> 
            <v-flex xs12 md8 >
                <h3  class="grey--text text--darken-2  text-right py-1">
                    TOTAL CLASSES PURCHASED : {{ascc.totalClasses}}
                </h3>
                
            </v-flex>
            <v-flex xs12 md12 pl-3>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                       ASHA SHARATH CULTURAL CENTER - COMPETITION
                    </h3>
              </div>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left green--text">
                                    #
                                </th>
                                <th class="text-left green--text">
                                    COURSE
                                </th>
                                <th class="text-left green--text">
                                    PURCHASED USER
                                </th>
                                <th class="text-left green--text">
                                    DETAILS
                                </th>
                        </tr>
                        </thead>
                        <tbody >
                            <tr
                                v-for="(item,index) in ascc.competition"
                                :key="item.id"
                            >
                            <td>{{ index+1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.count }}</td>
                            <td> <v-btn @click="details(item.id,item.name)" text ><v-icon>search</v-icon></v-btn>  </td>
                        </tr>
                        </tbody>
                        </template>
                </v-simple-table>
              </v-flex> 
            </v-layout>
        </v-flex>
        <v-flex xs12 md8>
                <h3  class="grey--text text--darken-2 text-right py-1 my-5">
                    TOTAL COMPETITIONS PURCHASED : {{ ascc.totalCompetition }}
                </h3> 
            </v-flex>
    </v-layout>
</template>

<script>
import {BASE_URL} from '@/config.js'
import Axios from 'axios'
export default {
    data(){
        return{
            ascc: [],
            progress:false,
        }
    },
    async mounted(){
        this.progress = true
        let url = BASE_URL + "/all-purchase-request-tacount"
        let {data} = await Axios.get(url)
        this.ascc= data
        this.progress = false
            },
    methods:{
     async details(id,title){
        let payload = {id,title}
        this.$router.push({name : 'CourseWisePurchase',params : {id : id, title : title}})
        // this.$store.dispatch("SET_PARAMS",payload)
        }
    }
  
}
</script>

<style scoped>

</style>