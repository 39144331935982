<template>
    <v-layout row wrap class="mt-7">
        <v-flex xs12>
            <div class="white">
                    <h3 class="green--text py-1" style="text-align:center">
                        {{$route.params.title.toUpperCase()}}
                    </h3>
              </div>
              </v-flex>
            <v-flex xs12>
                <p class="white pa-3 green--text">Total Purchases - <b>{{purchases.length}} </b></p>
            </v-flex>
             <v-flex xs12 >
                <v-simple-table class="mb-10">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                            <th class="text-left green--text">
                                NAME
                            </th>
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <th class="text-left green--text">
                                <v-menu offset-y >
                                    <template v-slot:activator="{ on, attrs }">
                                        DISTRICT
                                        <button
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <V-icon  color="green" class="mb-1"> mdi-menu-down</V-icon>
                                    </button>
                                </template>
                                    <v-list>
                                        <v-list-item
                                        v-for="(item, index) in items"
                                        :key="index"
                                        >
                                        <v-list-item-title >
                                            <v-btn text @click="filterDistrict(item.title)" style="text-transform: capitalize;">{{ item.title }}</v-btn>
                                        </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                            </th>
                            <th class="text-left green--text">
                                DATE OF PURCHASE
                            </th>
                            <!-- <th class="text-left green--text">
                                ACTION
                            </th> -->
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                                v-for="(item,index) in purchases" 
                                :key="item._id"
                               
                            >
                            <td>{{ index+1 }}</td>
                        
                            <td><b>{{ item.user.name }}</b></td>
                            <td>{{$route.params.title}}</td>
                            <td>{{ item.user.district }}</td>
                            <td v-if="item.created">{{ item.created | moment("Do MMM YYYY, h:mm:ss a") }}</td>
                            <td v-else>NA</td>
                            
                            </tr>
                        </tbody>
                        
                        </template>
                </v-simple-table>
                
        </v-flex>
        <!-- <v-flex v-else md12 class="text-center mt-6">
                <span class="white px-3">No purchased users in this course</span>
        </v-flex> -->
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'

export default {

    data(){
        return {
            userDetails : {},
            purchases : [],
            sorted_list : [],
            items: [
                    { title: 'Alappuzha' },
                    { title: 'Ernakulam' },
                    { title: 'Idukki' },
                    { title: 'Kannur' },
                    { title: 'Kasaragod' },
                    { title: 'Kollam' },
                    { title: 'Kottayam' },
                    { title: 'Kozhikode' },
                    { title: 'Malappuram' },
                    { title: 'Palakkad' },
                    { title: 'Pathanamthitta' },
                    { title: 'Thiruvananthapuram' },
                    { title: 'Thrissur' },
                    { title: 'Wayanad' },
                    { title: 'Others'}
                ],
        }
    },

    async mounted(){
        {
            let url = BASE_URL + "/purchase-by-course-ascc?id="+ this.$route.params.id
            let {data} = await Axios.get(url)
            this.purchases = data.reverse()
        }        
    },
      methods : {
        franchiseDetails(id,user){
            this.$router.push({name : 'FranchiseDetail',params : {id : id, user : user}})
        },
        details(id){
            this.$router.push({name : 'userDetails',params : {id : id}})
        },
      async  filterDistrict(district){
            let url = BASE_URL + "/purchase-by-course-ascc?id="+ this.$route.params.id
            let {data} = await Axios.get(url)
            let filteredDistrict = data.reverse()
            filteredDistrict = filteredDistrict.filter((item) =>{
                if(item.user.district != null){
                    return(item.user.district.toUpperCase() == district.toUpperCase())
                }
                else if(district == "Others"){
                    return (item.user.district == null)
                }
            })
            this.purchases = filteredDistrict
        }
      },
    
}
</script>