import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    login : false,
    // params : {}
    
  },
  getters : {
    LOGIN: state => {
      return state.login
    },
    // PARAMS : state => {
    //   return state.params
    // }

    
  },
  mutations: {
    SET_LOGIN: (state, payload) => {
      state.login = payload
  },
//   SET_PARAMS: (state, payload) => {
//     state.params = payload
// },

  },
  actions: {
    SET_LOGIN : async (context, payload) => {
      context.commit("SET_LOGIN",payload)
    },
    // SET_PARAMS : async (context,payload) => {
    //   context.commit("SET_PARAMS" ,payload)
    // }
  },
  modules: {
    
  }
})
